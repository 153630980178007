import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './root-saga';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

// Import Custom Component
import cartReducer from './cart/reducer';
import wishlistReducer from './wishlist';
import modalReducer from './modal';
import demoReducer from './demo';
import categoriesReducer from './categories';
import checkoutReducer from './checkout/reducer';

import { configureStore } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();

export const makeStore = context => {
  const store = configureStore({
    reducer: {
      cart: cartReducer,
      wishlist: wishlistReducer,
      modal: modalReducer,
      demo: demoReducer,
      categories: categoriesReducer,
      checkout: checkoutReducer
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER
          ]
        }
      }).concat(sagaMiddleware)
  });

  store.sagaTask = sagaMiddleware.run(rootSaga);
  store.__persistor = persistStore(store);
  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
