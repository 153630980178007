export default function calculateCartTotal (cartItems, key) {

  let total = cartItems.reduce((acc, current) => {

    let cost = 0;

    if (key === 'price') cost = (current.price[0] || 0) * (current.qty || 0);
    if (key === 'fee') cost = (current.shippingFee || 0) * (current.qty || 0);
    if (key === 'saved') cost = (current.price[1] - current.price[0] || 0) * (current.qty || 0);

    return acc + Number(cost.toFixed(2));
  }, 0);


  return total;

}