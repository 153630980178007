import {useEffect} from "react";
import {connect} from 'react-redux';


import { actions } from '../store/modal';
import { stickyInit, scrollTopInit } from '../utils';
import SecondaryHeader from './common/SecondaryHeader';
import SecondaryFooter from './common/SecondaryFooter';

function SecondaryLayout({ children, hideQuickView, hideVideo }) {
    useEffect(() => {
        window.addEventListener('scroll', stickyInit, { passive: true });
        window.addEventListener('scroll', scrollTopInit, { passive: true });
        window.addEventListener('resize', stickyInit);
        hideQuickView();
        hideVideo();

        return () => {
            window.removeEventListener('scroll', stickyInit, { passive: true });
            window.removeEventListener('scroll', scrollTopInit, {
                passive: true
            });
            window.removeEventListener('resize', stickyInit);
        };
    }, []);

    return (
        <>
            <div className="page-wrapper">
                 <SecondaryHeader />
                {children}
                 <SecondaryFooter />
            </div>
        </>
    );
}

export default connect(null, actions)(SecondaryLayout);
