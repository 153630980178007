import React from 'react';
import ALink from '../../ALink';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setCategory } from '../../../../store/categories';
import { sections } from '../../../../utils/data/shop';
import { useDispatch, useSelector } from 'react-redux';
import SelectedProductsBlock from '../../../partials/home/selected-products-block';
import { ThreeCircles } from 'react-loader-spinner';

const sortedSubcategories = {
  0: ['Beds, Frames & Bases','Nightstands','Dressers, Chests & Wardrobes', 'Bedroom Sets','Benches & Stools','Bedroom Storage','Makeup Vanities','Daybeds','Vanity Sinks'],
  1: ['Dining and Kitchen Sets','Seating for Dining','Servers, Sideboards & Buffets','Kitchen Islands & Carts','Table Benches','Wine Racks','Dining Tables'],
  2: ['Sofas','TV & Entertainment Furniture','Cabinets','Sectionals','Tables','Storage Benches','Loveseats','Chairs & Accent Seating','Display, Shelving & Etageres','Ottomans','Rocking Chairs','Indoor Fireplaces','Bean Bag Chairs & Lazy Sofa Chair','Recliners & Massage Chairs',"Kids' area"],
  3: ['Desks & Work Surfaces','Office Chairs','File Cabinets & Storage Cabinets'],
  4: ['Fitness Exercise Bikes','Sports Outdoor Bikes','Fitness Trampolines','Mobile Scooters'],
  5: ['Patio Furniture Sets','Canopies & Gazebos','Patio Seating','Garden Supplies','Outdoor Tables','Water Fountains','Outdoor Heating','Accessories'],
  6: ['Bedding Sets','Full Length Mirrors','Bathroom Lighting','Blankets & Pillows','Bathroom Mirrors','Dining Room Lighting','Sheets & Pillowcases','Wall Décor','Living Room Lighting','Rugs','Flowers & Plants','Outdoor Lighting','Wall Treatment & Supplies', 'Christmas Trees'],
}

export default function CategoryContainer({ products, handleCatIndex, addInfo, categoryIndex}) {
  const dispatch = useDispatch();
  const subCategories = useSelector(state => state.categories.categories[categoryIndex].children);
  const subCategoriesAmount = useSelector(state => state.categories.subCategories);

  if (!subCategoriesAmount.length) {
    return (
      <div className="header-spinner">
        <div className='spinner'>
          <ThreeCircles
          visible={true}
          height="100"
          width="100"
          color="#117a8b"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
    )
  }
  
  const productsAmountMap = subCategoriesAmount.reduce((map, item) => {
    map[item.subcategoryName] = item.productsAmount;
    return map;
  }, {});

  const sortedCategories = subCategories
    .filter(category => sortedSubcategories[categoryIndex].includes(category.name) && productsAmountMap[category.name] > 0)
    .sort((a, b) => sortedSubcategories[categoryIndex].indexOf(a.name) - sortedSubcategories[categoryIndex].indexOf(b.name));

  return (
    <>
      <div
        className={
          ['Patio & Outdoor', 'Fitness & Fun'].includes(products.name)
            ? 'product-grid-two'
            : 'product-grid-three'
        }>
        {sortedCategories.map((item, i) => (
          <ALink
            key={item.name + i}
            className={`d-flex align-center category-item ${item.name === 'Dining Tables' ? 'category-item-aling' : ''}`}
            href={{
              pathname: '/shop',
              query: {
                filters: JSON.stringify({
                  [sections.categories]: [item.name],
                  [sections.price]: { min: 0, max: 0 },
                  [sections.materials]: [],
                  [sections.colors]: []
                })
              }
            }}
            onClick={() => {
              handleCatIndex();
              dispatch(setCategory(item.name));
            }}>
            <LazyLoadImage
              className={
                ['Patio & Outdoor', 'Home Décor', 'Living Room'].includes(
                  products.name
                )
                  ? 'category-small-img'
                  : 'category-big-img'
              }
              src={`/images/category/header/${item.name}.png`}
            />
            <span
              className={
                ['Patio & Outdoor', 'Home Décor', 'Living Room'].includes(
                  products.name
                )
                  ? 'category-text-small '
                  : 'category-text-big'
              }>
              {item.name}
            </span>
          </ALink>
        ))}
        <div className={
          ['Patio & Outdoor', 'Fitness & Fun'].includes(products.name)
            ? 'product-self-grid-two'
            : 'product-self-grid-three'
        }>
          <SelectedProductsBlock listType={addInfo.listType} title={addInfo.title} limit={addInfo.limit} categoryName={products.name}/>
        </div>
      </div>
    </>
  );
}
