import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { actions as CartAction } from '../../../store/cart/actions';
import ALink from '../ALink';
import { getCartTotal, getSaveTotal } from '../../../utils';
import { useAxios } from '../../../hooks/useAxios/useAxios';
import { useAuthModalStore } from '../../../store/auth-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import calculateCartTotal from '../../../utils/calculateCartTotal';
import Image from 'next/image';

function CartMenu(props) {
  const { cartItems, saved_amount } = props;
  const saveTotalUnregistered = getSaveTotal(cartItems);

  const router = useRouter();
  const { isUserLogged } = useAuthModalStore();

  // console.log('CartMenu props', props);

  const { execute: getCart } = useAxios(
    '/buyontrust/storefront/furniture/cart',
    {},
    false,
    data => {
      if (data) {
        const availableCartItems = data.cartItems?.filter(
          item => item.dd_available === true
        );

        const cartItemsCost = calculateCartTotal(availableCartItems, 'price');
        const shippingCost = calculateCartTotal(availableCartItems, 'fee');
        const savedCost = calculateCartTotal(availableCartItems, 'saved');

        props.refreshCartStore({
          ...data,
          cartItems: availableCartItems,
          availableCartItems,
          shipping_cost: Number(shippingCost).toFixed(2),
          cart_amount: Number(cartItemsCost + shippingCost).toFixed(2),
          saved_amount: Number(savedCost.toFixed(2)),
          base_amount: Number(cartItemsCost.toFixed(2))
        });
      }
    }
  );

  const { execute: postCart } = useAxios(
    '/buyontrust/storefront/furniture/cart',
    { method: 'post' },
    false
  );

  useEffect(() => {
    if (isUserLogged) {
      if (cartItems?.length) {
        postCart({ cartItems: cartItems });
      }
      getCart(); // <--
    }
  }, [isUserLogged]);

  useEffect(() => {
    router.events.on('routeChangeStart', cartClose);

    return () => {
      router.events.off('routeChangeStart', cartClose);
    };
  }, []);

  function toggleCart(e) {
    e.preventDefault();
    document.querySelector('body').classList.toggle('cart-opened');
  }

  function cartClose() {
    document.querySelector('body').classList.contains('cart-opened') &&
      document.querySelector('body').classList.remove('cart-opened');
  }

  function getQtyTotal(items) {
    let total = 0;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        total += parseInt(items[i].qty, 10);
      }
    }
    return total;
  }

  function removeFromCart(e, product) {
    e.preventDefault();
    props.removeFromCart(product);
  }

  // console.log(cartItems);
  return (
    <div className="dropdown cart-dropdown">
      <a
        className="dropdown-toggle cart-toggle"
        href="#"
        onClick={toggleCart}
        title="Cart">
        <Image
          src={'/images/cart-icon.svg'}
          width={32}
          height={32}
          style={{ maxWidth: 'none' }}
        />
        <span className="cart-count badge-circle">
          {getQtyTotal(cartItems)}
        </span>
        <Image
          src={'/images/menu-item-icon.svg'}
          width={10}
          height={14}
          style={{ marginLeft: '12px', transform: 'rotate(90deg)' }}
        />
      </a>

      <div className="cart-overlay" onClick={cartClose} />

      <div className="dropdown-menu mobile-cart">
        <a
          className="btn-close"
          href="#"
          onClick={e => {
            cartClose();
            e.preventDefault();
          }}
          title="Close (Esc)">
          ×
        </a>

        <div className="dropdownmenu-wrapper">
          <div className="dropdown-cart-header">Shopping Cart</div>

          {cartItems?.length > 0 ? (
            <>
              <div className="dropdown-cart-products">
                {cartItems?.map((product, index) => (
                  <div className="product" key={'cartItems' + index}>
                    <div className="product-details">
                      <h2 className="product-title">
                        {product.index > -1 ? (
                          !product.variants[product.index]?.color ? (
                            <ALink href={`/product/default${product.slug}`}>
                              {product.name +
                                // ' - ' +
                                (product?.variants[product.index]?.size.name ||
                                  '')}
                            </ALink>
                          ) : !product.variants[product.index]?.size ? (
                            <ALink href={`/product/default${product.slug}`}>
                              {product.name +
                                ' - ' +
                                product.variants[product.index].color.name}
                            </ALink>
                          ) : (
                            <ALink href={`/product/default${product.slug}`}>
                              {product.name +
                                ' - ' +
                                product.variants[product.index]?.color.name +
                                ', ' +
                                product.variants[product.index]?.size.name}
                            </ALink>
                          )
                        ) : (
                          <ALink href={`/product/default${product.slug}`}>
                            {product.name}
                          </ALink>
                        )}
                      </h2>

                      <span className="cart-product-info">
                        <div className="price-box price-box-home">
                          <span className="cart-product-qty">
                            {product.qty}
                          </span>{' '}
                          ×{' '}
                          {product?.sale_end?.sale_in_seconds > 0 ? (
                            <>
                              <span className="cart-product-qty">
                                {'$' + product?.price[0]?.toFixed(2)}
                              </span>
                              <span className="old-price cart-product-qty ml-2">
                                {'$' + product?.price[1]?.toFixed(2)}
                              </span>

                              <div className="product-home-sale">
                                <span className="product-home-sale-text">
                                  <span className="d-flex">
                                    <LazyLoadImage
                                      width={11}
                                      src="/images/fire.svg"
                                    />
                                    <div className={'ml-2'}>Sale Ends in:</div>
                                  </span>
                                  <span className="product-home-sale-text-lower">
                                    {/* need field 'promotionTo' & 'promotionFrom' from BE */}
                                    <span className="product-home-sale-text-bold">
                                      {product.sale_end.days}
                                    </span>{' '}
                                    d{' '}
                                    <span className="product-home-sale-text-bold">
                                      {product.sale_end.hours}
                                    </span>{' '}
                                    h{' '}
                                    <span className="product-home-sale-text-bold">
                                      {product.sale_end.minutes}
                                    </span>{' '}
                                    m{' '}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <span className="cart-product-qty">
                              {'$' + product?.price[0]?.toFixed(2)}
                            </span>
                          )}
                        </div>
                      </span>
                    </div>

                    <figure className="product-image-container">
                      <ALink
                        className="product-image"
                        href={`/product/default${product.slug}`}>
                        <img
                          alt="product"
                          height="78"
                          src={
                            product?.small_pictures?.length
                              ? (!product?.small_pictures[0]?.url?.includes?.(
                                  'https:'
                                )
                                  ? process.env.NEXT_PUBLIC_ASSET_URI
                                  : '') + product?.small_pictures[0]?.url
                              : ''
                          }
                          width="78"
                        />
                      </ALink>
                      <a
                        className="btn-remove icon-cancel"
                        href="#"
                        onClick={e => {
                          removeFromCart(e, product);
                        }}
                        title="Remove Product"
                      />
                    </figure>
                  </div>
                ))}
              </div>
              <div className="dropdown-cart-total mb-0">
                <span>SUBTOTAL:</span>

                <span className="cart-total-price float-right">
                  ${getCartTotal(cartItems).toFixed(2)}
                </span>
              </div>

              <div className="dropdown-cart-total">
                <span className={'text-muted'}>Saved:</span>
                {isUserLogged && (
                  <span className="cart-total-price float-right text-muted">
                    {saved_amount?.toFixed(2) > 0
                      ? '-$' + saved_amount?.toFixed(2)
                      : '$' + (0).toFixed(2)}
                  </span>
                )}
                {!isUserLogged && (
                  <span className="cart-total-price float-right text-muted">
                    {saveTotalUnregistered > 0
                      ? '-$' + saveTotalUnregistered.toFixed(2)
                      : '$' + (0).toFixed(2)}
                  </span>
                )}
              </div>

              <div className="dropdown-cart-action">
                <ALink
                  className="btn btn-gray btn-block view-cart"
                  href="/cart">
                  View Cart
                </ALink>
                <ALink
                  className="btn btn-dark btn-block text-white"
                  href="/checkout">
                  Checkout
                </ALink>
              </div>
            </>
          ) : (
            <p className="pt-3 mt-2">No products in the cart.</p>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...(state.cart ? state.cart : {})
  };
}

export default connect(mapStateToProps, CartAction)(CartMenu);
