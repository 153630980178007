import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from './ALink';
import cn from 'classnames';
import { Collapse } from '@kunukn/react-collapse';
import useApproveModalStore from '../../store/approve-modal';
import Link from 'next/link';

function SecondaryFooter() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const [isExpand, setIsExpand] = useState({
    about: false,
    accountsAndOrders: false
  });

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const toggleSection = section => {
    setIsExpand(prevSections => ({
      ...prevSections,
      [section]: !prevSections[section]
    }));
  };

  return (
    <footer className="footer-container">
      {isMobile ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row row-sm">
                  <div className="col-sm-4">
                    <div className="widget">
                      <ALink href={''}>
                        <LazyLoadImage
                          width={168}
                          height={32}
                          src="/images/logo.png"
                        />
                      </ALink>
                      <ul className="contact-info mb-3 mt-3">
                        <li>
                          <span className="contact-info-label">
                            The nations’ leading provider in lease-to-own
                            furniture and electronics
                          </span>
                        </li>
                      </ul>
                      <div className="footer-contacts-network">
                        <ALink
                          href="https://x.com/BuyOnTrust"
                          className={'footer-contacts-link'}
                          target="_blank">
                          <LazyLoadImage src="/images/logo-x.svg" />
                        </ALink>
                        <ALink
                          href="https://www.facebook.com/BuyonTrust/"
                          className={'footer-contacts-link'}
                          target="_blank">
                          <LazyLoadImage src="/images/logo-fb.svg" />
                        </ALink>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="footer-widget-block">
                      <h4 className="widget-title widget-title-mobile">
                        About
                      </h4>
                      <button
                        className={cn('footer-widget-button', {
                          'icon-angle-up': isExpand.about,
                          'icon-angle-down': !isExpand.about
                        })}
                        onClick={() => toggleSection('about')}></button>
                    </div>

                    <Collapse
                      isOpen={isExpand.about}
                      transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                      className="collapse-mt">
                      <ul className="links">
                        <li>
                          <Link href="#how-it-works">How it works?</Link>
                        </li>
                        <li>
                          <ALink href="/contact-us">Contact us</ALink>
                        </li>
                      </ul>
                    </Collapse>
                  </div>

                  <span className="footer-widget-line"></span>

                  <div className="col-sm-3">
                    <div className="footer-widget-block">
                      <h4 className="widget-title widget-title-mobile">
                        Accounts & Orders
                      </h4>
                      <button
                        className={cn('footer-widget-button', {
                          'icon-angle-up': isExpand.accountsAndOrders,
                          'icon-angle-down': !isExpand.accountsAndOrders
                        })}
                        onClick={() =>
                          toggleSection('accountsAndOrders')
                        }></button>
                    </div>
                    <Collapse
                      isOpen={isExpand.accountsAndOrders}
                      transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                      className="collapse-mt">
                      <ul className="links">
                        <li>
                          <ALink href="/returns-replacements">
                            Returns & Replacements
                          </ALink>
                        </li>
                        <li>
                          <ALink href="/cancellations">Cancellations</ALink>
                        </li>
                      </ul>
                    </Collapse>
                  </div>

                  <span className="footer-widget-line"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="footer-bottom-mobile">
              <span className="footer-copyright">
                ©2024 BuyOnTrust. All rights reserved
              </span>
              <ALink href="/privacy-policy" className="copyright-text">
                Privacy & Policy
              </ALink>
              <ALink href="/terms-condition" className="copyright-text">
                Terms & Condition
              </ALink>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="footer-box">
            <div className="footer-contacts">
              <ALink href={''}>
                <LazyLoadImage width={168} height={32} src="/images/logo.png" />
              </ALink>
              <p className="footer-contacts-text">
                The nations’ leading provider in lease-to-own furniture and
                electronics
              </p>
              <div className="footer-contacts-network">
                <ALink
                  href="https://x.com/BuyOnTrust"
                  className={'footer-contacts-link'}
                  target="_blank">
                  <LazyLoadImage src="/images/logo-x.svg" />
                </ALink>
                <ALink
                  href="https://www.facebook.com/BuyonTrust/"
                  className={'footer-contacts-link'}
                  target="_blank">
                  <LazyLoadImage src="/images/logo-fb.svg" />
                </ALink>
              </div>
            </div>
            <div className="footer-menu">
              <div className="footer-about">
                <h5 className="footer-about-title">About</h5>
                <div className="footer-about-links">
                  <Link href="#how-it-works" className={'footer-about-link'}>
                    How it works?
                  </Link>
                  <ALink href="/contact-us" className={'footer-about-link'}>
                    Contact us
                  </ALink>
                </div>
              </div>
              <div className="footer-orders">
                <h5 className="footer-about-title">Accounts & Orders</h5>
                <div className="footer-orders-box">
                  <div className="footer-orders-login">
                    <ALink
                      href="/returns-replacements"
                      className={'footer-about-link'}>
                      Returns & Replacements
                    </ALink>
                    <ALink
                      href="/cancellations"
                      className={'footer-about-link'}>
                      Cancellations
                    </ALink>
                  </div>
                  <div className="footer-orders-order"></div>
                </div>
              </div>
              <ALink className={'up-arrow'} href={''}>
                <LazyLoadImage src="/images/up-arrow.svg" />
              </ALink>
            </div>
          </div>
          <div className="footer-copyright">
            <span className="copyright-text">
              ©2024 BuyOnTrust. All rights reserved
            </span>
            <div className="copyright-wrapper">
              <ALink href="/privacy-policy" className="copyright-text">
                Privacy & Policy
              </ALink>
              <ALink href="/terms-condition" className="copyright-text">
                Terms & Condition
              </ALink>
            </div>
          </div>
        </>
      )}
    </footer>
  );
}

export default React.memo(SecondaryFooter);
