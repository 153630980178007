import versionConfig from '../../environments/versionConfig';
import React, { useEffect, useState } from 'react';
import ALink from './ALink';
import {constructInitMarketingData} from '../../utils/utils';

export default function SecondaryHeader() {
  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const initMarketingData = constructInitMarketingData();

  const addHeaderClass = () => {
    let scrollYVal = window.scrollY;
    const header = document?.getElementById('landHead');
    if (scrollYVal > 600 && !header?.classList?.contains('header-fixed')) {
      header.classList.add('header-fixed');
      setIsBtnVisible(true);
    }
    if (scrollYVal < 70 && header?.classList?.contains('header-fixed')) {
      header.classList.remove('header-fixed');
      setIsBtnVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', addHeaderClass);

    return () => {
      document.removeEventListener('scroll', addHeaderClass);
    };
  }, []);

  const nodeEnv = process.env.NODE_ENV;
  console.log('Env =', nodeEnv);

  return (
    <>
      <header className={'header header-middle'} id="landHead">
        <div className="container">
          <div className="header-left">
            <ALink className="logo" href="/">
              <img alt="BuyOnTrust Logo" src="/images/home/logo_red.png" />
              <div className={'version'}>
                {nodeEnv !== 'production' ? `v ${versionConfig.version}` : ''}
              </div>
            </ALink>
          </div>
          {isBtnVisible && (
            <div className="btns-header-container">
              <ALink
                className="btn btn-dark btn-header mr-3"
                href={`${process.env.FURNITURE_URL}/home`}>
                Shop Furniture
              </ALink>
              <ALink
                className="btn btn-dark btn-header"
                href={{
                  pathname: process.env.ELECTRONICS_URL,
                  query: initMarketingData,
                }}
              >
                  Shop Electronics
              </ALink>
            </div>
          )}
          <div className="header-right d-lg-block">
            <div className="header-contact d-lg-flex justify-content-end align-items-center pl-1 mr-lg-5 pr-xl-2">
              <i className="icon-phone-2 info-fill" />
              <h6>
                <a className="font4" href="tel:+18882747732">
                  (888) 274-7732
                </a>
              </h6>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
