import { withRouter } from 'next/router';

// Import Custom Component
import ALink from '../../ALink';

// Import Utils
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { sections } from '../../../../utils/data/shop';
import { Collapse } from '@kunukn/react-collapse';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Image from 'next/image';

const sortedSubcategories = {
  0: [
    'Beds, Frames & Bases',
    'Nightstands',
    'Dressers, Chests & Wardrobes',
    'Bedroom Sets',
    'Benches & Stools',
    'Bedroom Storage',
    'Makeup Vanities',
    'Daybeds',
    'Vanity Sinks'
  ],
  1: [
    'Dining and Kitchen Sets',
    'Seating for Dining',
    'Servers, Sideboards & Buffets',
    'Kitchen Islands & Carts',
    'Table Benches',
    'Wine Racks',
    'Dining Tables'
  ],
  2: [
    'Sofas',
    'TV & Entertainment Furniture',
    'Cabinets',
    'Sectionals',
    'Tables',
    'Storage Benches',
    'Loveseats',
    'Chairs & Accent Seating',
    'Display, Shelving & Etageres',
    'Ottomans',
    'Rocking Chairs',
    'Indoor Fireplaces',
    'Bean Bag Chairs & Lazy Sofa Chair',
    'Recliners & Massage Chairs',
    "Kids' area"
  ],
  3: [
    'Desks & Work Surfaces',
    'Office Chairs',
    'File Cabinets & Storage Cabinets'
  ],
  4: [
    'Fitness Exercise Bikes',
    'Sports Outdoor Bikes',
    'Fitness Trampolines',
    'Mobile Scooters'
  ],
  5: [
    'Patio Furniture Sets',
    'Canopies & Gazebos',
    'Patio Seating',
    'Garden Supplies',
    'Outdoor Tables',
    'Water Fountains',
    'Outdoor Heating',
    'Accessories'
  ],
  6: [
    'Bedding Sets',
    'Full Length Mirrors',
    'Bathroom Lighting',
    'Blankets & Pillows',
    'Bathroom Mirrors',
    'Dining Room Lighting',
    'Sheets & Pillowcases',
    'Wall Décor',
    'Living Room Lighting',
    'Rugs',
    'Flowers & Plants',
    'Outdoor Lighting',
    'Wall Treatment & Supplies',
    'Christmas Trees'
  ]
};

function CategoryMobile({ router, setIsMobileMenuOpen }) {
  const query = router.query;
  const categories = useSelector(state => state.categories.categories);
  const subCategoriesAmount = useSelector(
    state => state.categories.subCategories
  );

  const [subcategoriesState, setSubcategoriesState] = useState(() =>
    categories.map(() => false)
  );

  function toggleSlider(index) {
    setSubcategoriesState(prevStates =>
      prevStates.map((prevState, i) => (i === index ? !prevState : prevState))
    );
  }

  const productsAmountMap = subCategoriesAmount.reduce((map, item) => {
    map[item.subcategoryName] = item.productsAmount;
    return map;
  }, {});

  return (
    <>
      <div
        onClick={() => setIsMobileMenuOpen(false)}
        className="category-overlay"
      />
      <nav className="mobile-category-sidebar">
        <ul>
          <li>
            <div className="burger-menu">
              <button
                className="burger-button m-0"
                onClick={() => setIsMobileMenuOpen(false)}>
                <LazyLoadImage src="/images/close.svg" />
              </button>
            </div>
          </li>
          <li className="mt-2">
            <ALink
              onClick={() => setIsMobileMenuOpen(false)}
              className="d-flex align-items-center mb-2"
              href="/home">
              <img src="/images/home/home-icon.svg" alt="Home button" />
              <h5 className="m-0 ml-1 category-link-mobile">Home</h5>
            </ALink>
            {categories.map((cat, i) => {
              if (cat.name !== 'Hot Sale') {
                const sortedSubCategories = cat.children
                  .filter(
                    sub =>
                      sortedSubcategories[i]?.includes(sub.name) &&
                      productsAmountMap?.[sub.name] > 0
                  )
                  .sort(
                    (a, b) =>
                      sortedSubcategories[i]?.indexOf(a.name) -
                      sortedSubcategories[i]?.indexOf(b.name)
                  );

                return (
                  <div
                    className="mb-2"
                    onClick={() => toggleSlider(i)}
                    key={`${cat.name}-${i}`}>
                    <div className="d-flex">
                      <h5
                        className={`m-0 pointer category-link-mobile category-link-mobile-arrow ${
                          subcategoriesState[i]
                            ? 'category-link-mobile-active'
                            : ''
                        }`}>
                        {cat.name}
                      </h5>
                      <Image
                        src={
                          subcategoriesState[i]
                            ? '/images/menu-item-icon-active.svg'
                            : '/images/menu-item-icon.svg'
                        }
                        width={10}
                        height={14}
                        style={{
                          cursor: 'pointer',
                          fontWeight: '600',
                          marginLeft: '5px'
                        }}
                      />
                    </div>

                    <Collapse
                      isOpen={subcategoriesState[i]}
                      transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                      {sortedSubCategories.map((sub, j) => (
                        <ALink
                          key={`${sub.name}-${j}`}
                          className={'d-flex subcategory-link '}
                          href={{
                            pathname: '/shop',
                            query: {
                              ...query,
                              filters: JSON.stringify({
                                [sections.categories]: [sub.name],
                                [sections.price]: { min: 0, max: 0 },
                                [sections.materials]: [],
                                [sections.colors]: []
                              })
                            }
                          }}
                          onClick={() => setIsMobileMenuOpen(false)}>
                          {sub.name}
                        </ALink>
                      ))}
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <div className="d-flex">
                    <LazyLoadImage src="/images/fire.svg" className="mr-1" />
                    <ALink
                      style={{ color: '#000' }}
                      key={`${cat.name}-${i}`}
                      className={'d-flex category-link-mobile'}
                      href={{
                        pathname: '/shop',
                        query: {
                          ...query,
                          filters: JSON.stringify({
                            [sections.categories]: [cat.name],
                            [sections.price]: { min: 0, max: 0 },
                            [sections.materials]: [],
                            [sections.colors]: []
                          })
                        }
                      }}
                      onClick={() => setIsMobileMenuOpen(false)}>
                      {cat.name}
                    </ALink>
                  </div>
                );
              }
            })}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default withRouter(CategoryMobile);
