import React from 'react';
import SelectedProductsItem from './selected-products-item';
import { useAxios } from '../../../hooks/useAxios/useAxios';

function SelectedProductsBlock({ listType, title, limit = 3, categoryName }) {
  const { data, error } = useAxios('/public/buyontrust/products', {
    params: {
      page: 0,
      limit: limit,
      category: categoryName,
      store_type: 'furniture',
      list_type: listType
    }
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div className="selected-products-block">
      <h3 className="selected-products-block-title">{title}</h3>
      {data?.data?.map((item, i) => {
        if (i > 1) return;
        return <SelectedProductsItem key={item.name + i} product={item} />;
      })}
    </div>
  );
}

export default React.memo(SelectedProductsBlock);
