import { connect } from 'react-redux';

// Import Custom Component
import ALink from '../ALink';

function StickyNavbar ( { cartItems } ) {
  function getQtyTotal ( items ) {
    let total = 0;
    if ( items ) {
      for ( let i = 0; i < items.length; i++ ) {
        total += parseInt( items[ i ].qty, 10 );
      }
    }
    return total;
  }

  return (
    <div className="sticky-navbar d-flex justify-content-start">
      <div className="sticky-info">
        <ALink href="/"><i className="icon-home" />Home</ALink>
      </div>
      <div className="sticky-info">
        <ALink
          className=""
          href="/shop"
        ><i className="icon-bars" />Categories</ALink>
      </div>
      <div className="sticky-info">
        <ALink
          className=""
          href="/login"
        ><i className="icon-user-2" />Account</ALink>
      </div>
      <div className="sticky-info">
        <ALink
          className=""
          href="/cart"
        >
          <i className="icon-shopping-cart position-relative">
            <span className="cart-count badge-circle">{ getQtyTotal( cartItems ) }</span>
          </i>Cart
        </ALink>
      </div>
    </div>
  )
}

function mapStateToProps ( state ) {
  return {
    cartItems: state.cart?.cartItems?.length ? state.cart?.cartItems : []
  }
}

export default connect( mapStateToProps, null )( StickyNavbar );
