import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Provider, useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useRouter } from 'next/router';
import 'react-phone-input-2/lib/style.css';
import { wrapper } from '../store';
import Layout from '../components/layout';
import '../public/sass/style.scss';
import SecondaryLayout from '../components/SecondaryLayout';
import { useAuthModalStore } from '../store/auth-modal';
import { useAxios } from '../hooks/useAxios/useAxios';
import { ToastContainer } from 'react-toastify';
import { logRocketInit } from '../utils/logrocket';

const defaultMarketingData = {
  pid: '3143', // required
  sid: '9337', // required
  eid: '11248961938-105561120410-m-appliances%20lease%20to%20own%20bad%20credit', // required
  // oid: '3323',
  // gclid: '35354sdf77',
};

logRocketInit();

const App = ({ Component, pageProps }) => {
  const store = useStore();
  const router = useRouter();
  const {
    monitorUserSessionFromCookie,
    setUserData,
    ...state
  } = useAuthModalStore();

  useEffect(() => {
    if (router.isReady) {
      const { pid, sid, oid, eid, uid, gclid, hsid } = router.query;
      // Set input parameters for bigcomerce electronics store
      localStorage.setItem('initMarketingData', JSON.stringify({ pid, sid, oid, eid, uid, gclid, hsid }))

      const marketingData = {
        pid: pid || defaultMarketingData.pid,
        sid: sid || defaultMarketingData.sid,
        eid: eid || defaultMarketingData.eid,
        oid: oid || defaultMarketingData.oid, // undefined if is not set
        uid: uid || defaultMarketingData.uid, // undefined if is not set
        gclid: gclid || defaultMarketingData.gclid // undefined if is not set
      };
      // console.log('marketingData', marketingData);
      localStorage.setItem('marketingData', JSON.stringify(marketingData))
    }
  }, [router.isReady, router.query]);

  useAxios(
    '/public/buyontrust/auth/current_customer?store=furniture',
    { method: 'post' },
    state.isUserLogged,
    data => {
      if (data) {
        localStorage.setItem('customerData', JSON.stringify(data))
        setUserData(data)
      }
    }
  );

  useEffect(() => {
    console.log(
      'App launched on environment: ' +
                process.env.TARGET_ENVIRONMENT +
                '...'
    );

    // if (store.getState().demo.current !== 8) {ç
    //     store.dispatch(DemoAction.refreshStore(8));
    // }
  }, []);

  useEffect(() => {
    monitorUserSessionFromCookie();
  }, [state.isUserLogged, state.ddToken, state.jwtToken]);

  const CurrentLayout = router.pathname === '/' ? SecondaryLayout : Layout;
  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <div className="loading-overlay">
            <div className="bounce-loader">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          </div>
        }
        persistor={store.__persistor}
      >
        <Helmet>
          <meta charSet="UTF-8" />
          <meta
            content="IE=edge"
            httpEquiv="X-UA-Compatible"
          />
          <meta
            content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
            name="viewport"
          />

          <title>Buy On Trust</title>

          <meta
            content="React Template"
            name="keywords"
          />
          <meta
            content="Buy On Trust"
            name="description"
          />
          <meta
            content="SW-THEMES"
            name="author"
          />
        </Helmet>

        <CurrentLayout>
          <ToastContainer
            autoClose={3000}
            duration={300}
          />
          <Component {...pageProps} />
        </CurrentLayout>
      </PersistGate>
    </Provider>
  );
};

// App.getInitialProps = async ({ Component, ctx }) => {
//     let pageProps = {};
//     if (Component.getInitialProps) {
//         pageProps = await Component.getInitialProps(ctx);
//     }
//     return { pageProps };
// };

export default wrapper.withRedux(App);
