import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setLocalStorage } from '../../../utils/storage/storage';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { defaultQueryParamsForShop } from '../../constants';

const uownSrc = process.env.NEXT_PUBLIC_UOWN_BASE_URL || '';
export default function HowItWorks({
  isChecked,
  setIsChecked,
  setIsOpenModal,
  setIsShowLeaseModal,
  handleClose
}) {
  const [isApproved, setIsApproved] = useState(false);

  const customerEmail = JSON.parse(localStorage.getItem('customerData'))
    ?.customer?.email;

  const router = useRouter();

  const updateQueryParams = () => {
    let newQuery = { ...router.query };
    delete newQuery['modal'];

    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    );

    let newQueryToString = '';
    if (new URLSearchParams(newQuery).toString().length) {
      newQueryToString = `?${new URLSearchParams(newQuery).toString()}`;
    } else {
      // Set default
      if (router.pathname === '/shop') {
        newQueryToString = defaultQueryParamsForShop; // '?search=&filters=%7B"categories"%3A%5B%5D%2C"price"%3A%7B"min"%3A0%2C"max"%3A0%7D%2C"materials"%3A%5B%5D%2C"colors"%3A%5B%5D%7D&page=1}';
      }
    }

    const newUrl = `${window.location.origin}${router.pathname}${newQueryToString}`;
    window.history.replaceState({}, '', newUrl);

    window.location.reload();
  };

  const handleModifyCart = () => {
    window.location.href = '/cart';
  };
  const handleOpen = () => {
    setLocalStorage('showHowItWorks', isChecked);
    // setIsOpenModal(false); // <-- we need this modal opened to catch messages from iframe !!!
    setIsShowLeaseModal(true);
  };

  useEffect(() => {
    const customerData = localStorage.getItem('customerData');

    if (customerData) {
      const parsedData = JSON.parse(customerData);
      const leaseData = parsedData.lease_data || [];
      const approvedLease = leaseData.find(
        lease => lease?.name === 'uown_furniture' && lease?.application_status
      );
      if (approvedLease?.application_status === 'approved') {
        setIsApproved(true);
      }
    }
  }, []);

  const onMessageReceivedFromIframe = event => {
    if (event.origin === uownSrc) {
      console.log('--- HowItWorks => onMessageReceivedFromIframe ---');
      console.log(event);

      const timeStamp = new Date().toString().substring(4, 33);

      if (!event.data) {
        console.log('MessageData: no data received');
      } else if (typeof event.data === 'string') {
        console.log(
          `MessageData: ${event.data} (${customerEmail}, ${timeStamp})`
        );
      } else {
        console.log(
          `MessageData: ${JSON.stringify(
            event.data
          )} (${customerEmail}, ${timeStamp})`
        );
      }

      try {
        if (event.data.label === 'uown-reload-checkout') {
          // Redirect to cart for modify
          console.log(
            'HowItWorks => onMessageReceivedFromIframe --> uown-reload-checkout'
          );
          handleModifyCart();
        }

        if (event.data.label === 'uown-finished') {
          // The application was successfully completed
          console.log(
            'HowItWorks => onMessageReceivedFromIframe --> uown-finished'
          );

          // Not sure if we must analyze event.data ?
          // setIsOpenModal(false)
          // setIsShowLeaseModal(false);
          // updateQueryParams();
        }

        if (event.data.label === 'uown-finalize') {
          // The application was successfully completed FOR NOT LOGGED IN CUSTOMER
          console.log(
            'HowItWorks => onMessageReceivedFromIframe --> uown-finalize'
          );

          // Not sure if we must analyze event.data ?
          router.reload()
          setIsOpenModal(false);
          setIsShowLeaseModal(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      // console.log('HowItWorks =>  onMessageReceivedFromIframe exit due origin is not match', event.origin );
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe, false);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromIframe);
    };
  }, []);

  return (
    <div className="how-it-works overflow-auto text-center">
      <div className="p-4">
        <h3 className="how-it-works-title">
          {isApproved
            ? 'It looks like you have been approved'
            : "It looks like you haven't been approved"}
        </h3>
        <p className="how-it-works-desc">
          With a small initial payment, usually less than <strong>$50,</strong>{' '}
          you can afford to shop from all the top brand names.
          <br />
          <br />
          Don't wait, find out how much you qualify for right now.
        </p>
      </div>
      <h4 className="how-it-works-sub mt-2">HOW IT WORKS</h4>
      <div className="how-it-works-wrapper d-flex">
        <div className="how-it-works-card">
          <LazyLoadImage
            className="how-it-works-img"
            src="/images/howitworks1.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">1</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">
                Get approved for up to $5,000
              </h5>
              <p>The process is fast, easy, and approval is instant</p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            className="how-it-works-img"
            src="/images/howitworks2.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">2</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">Start Shopping</h5>
              <p>Over 1 Million new, brand name, products</p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            className="how-it-works-img"
            src="/images/howitworks3.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">3</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">Checkout</h5>
              <p>
                Provide a delivery address and sign your lease electronically.
              </p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            className="how-it-works-img"
            src="/images/howitworks4.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">4</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">
                Your furniture is shipped directly to your home
              </h5>
              <p>You can track your delivery status online</p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="how-it-works-simple">It's just that simple!</h4>
      {isApproved ? (
        <>
          <div className={'header-approval-button '}>
            <a className="pointer" onClick={handleClose} target="_blank">
              Got it
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 cla" />
        </>
      ) : (
        <>
          <div className={'header-approval-button '}>
            <a className="pointer" onClick={handleOpen} target="_blank">
              Get&nbsp;Approved!
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 cla">
            <label className="d-flex align-items-center how-it-works-label">
              <input
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                type="checkbox"
                className='how-it-works-checkbox'
              />
              <span className="ml-3" />
              Don't show this message again
            </label>
          </div>
        </>
      )}
    </div>
  );
}
