import { actionTypes } from './constants';

export const actions = {
  addToCart: (product, qty = 1, index) => ({
    type: actionTypes.AddToCart,
    payload: { product, newQty: qty }
  }),
  removeFromCart: data => ({
    type: actionTypes.RemoveFromCart,
    payload: data
  }),
  updateCart: (product, qty = 1) => ({
    type: actionTypes.UpdateCart,
    payload: { product, newQty: qty }
  }),
  refreshCartStore: data => ({
    type: actionTypes.RefreshCartStore,
    payload: data
  }),
  getActualCart: data => ({
    type: actionTypes.GetActualCart,
    payload: data
  }),
  setAvailableCartItems: data => ({
    type: actionTypes.SetAvailableCartItems,
    payload: data
  }),
  setCartItems: data => ({
    type: actionTypes.SetCartItems,
    payload: data
  }),
  setInitialCartState: () => ({
    type: actionTypes.SetInitialCartState
  })
};
