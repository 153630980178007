import React, { useState, useEffect } from 'react';

import ALink from './ALink';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuthModalStore } from '../../store/auth-modal';
import { Collapse } from '@kunukn/react-collapse';
import cn from 'classnames';
import useApproveModalStore from '../../store/approve-modal';

function Footer() {
  const { isUserLogged, logOut } = useAuthModalStore();
  const { openModal } = useApproveModalStore();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const [isExpand, setIsExpand] = useState({
    about: false,
    accountsAndOrders: false
  });

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 575);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const toggleSection = section => {
    setIsExpand(prevSections => ({
      ...prevSections,
      [section]: !prevSections[section]
    }));
  };

  return (
    <footer className="footer">
      {isMobile ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row row-sm">
                  <div className="col-sm-4">
                    <div className="widget">
                      <a href="#" className="footer-link">
                        <LazyLoadImage
                          src="/images/logoBw1.png"
                          alt="Facebook"
                          width={168}
                          height={32}
                        />
                      </a>
                      <ul className="contact-info mb-3 mt-3">
                        <li>
                          <span className="contact-info-label">
                            The nations’ leading provider in lease-to-own
                            furniture and electronics
                          </span>
                        </li>
                      </ul>
                      <div className="social-icons">
                        <ALink
                          href="https://x.com/BuyOnTrust"
                          className="social-icon social-x icon-x"
                          title="Twitter"
                          target="_blank">
                          <LazyLoadImage src="/images/logo-x.svg" />
                        </ALink>
                        <ALink
                          href="https://www.facebook.com/BuyonTrust/"
                          className="social-icon"
                          title="Facebook"
                          target="_blank">
                          <LazyLoadImage src="/images/logo-fb.svg" />
                        </ALink>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="footer-widget-block">
                      <h4 className="widget-title widget-title-mobile">
                        About
                      </h4>
                      <button
                        className={cn('footer-widget-button', {
                          'icon-angle-up': isExpand.about,
                          'icon-angle-down': !isExpand.about
                        })}
                        onClick={() => toggleSection('about')}></button>
                    </div>

                    <Collapse
                      isOpen={isExpand.about}
                      transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                      className="collapse-mt">
                      <ul className="links">
                        <li>
                          <a
                            href="javascript:void(0)"
                            style={{ cursor: 'pointer' }}
                            onClick={openModal}>
                            How it works?
                          </a>
                        </li>
                        <li>
                          <ALink href="/contact-us">Contact us</ALink>
                        </li>
                      </ul>
                    </Collapse>
                  </div>

                  <span className="footer-widget-line"></span>

                  <div className="col-sm-3">
                    <div className="footer-widget-block">
                      <h4 className="widget-title widget-title-mobile">
                        Accounts & Orders
                      </h4>
                      <button
                        className={cn('footer-widget-button', {
                          'icon-angle-up': isExpand.accountsAndOrders,
                          'icon-angle-down': !isExpand.accountsAndOrders
                        })}
                        onClick={() =>
                          toggleSection('accountsAndOrders')
                        }></button>
                    </div>
                    <Collapse
                      isOpen={isExpand.accountsAndOrders}
                      transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                      className="collapse-mt">
                      <ul className="links">
                        <li>
                          {isUserLogged ? (
                            <a href="javascript:void(0)" onClick={logOut}>
                              Logout
                            </a>
                          ) : (
                            <ALink href="/login">Login or Sign Up</ALink>
                          )}
                        </li>
                        <li>
                          <ALink href="/returns-replacements">
                            Returns & Replacements
                          </ALink>
                        </li>
                        <li>
                          <ALink href="/cancellations">Cancellations</ALink>
                        </li>
                      </ul>
                    </Collapse>
                  </div>

                  <span className="footer-widget-line"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="footer-bottom-mobile">
              <span className="footer-copyright">
                ©2024 BuyOnTrust. All rights reserved
              </span>
              <ALink href="/privacy-policy" className="mr-5">
                Privacy & Policy
              </ALink>
              <ALink href="/terms-condition">Terms & Condition</ALink>
              {/* <div className="container d-sm-flex align-items-center">
                <div className="footer-left">
                  <span className="footer-copyright">
                    ©2024 BuyOnTrust. All rights reserved
                  </span>
                </div>

                <div className="footer-right ml-auto mt-1 mt-sm-0">
                  <div className="payment-icons">
                    <span>Privacy & Policy</span>
                    <br />
                    <span>Terms & Condition</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="footer-middle">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row row-sm d-flex d-flex justify-content-between">
                    <div className="col-sm-4">
                      <div className="widget">
                        <a href="#" className="footer-link">
                          <LazyLoadImage
                            src="/images/logoBw1.png"
                            alt="Facebook"
                            width={168}
                            height={32}
                          />
                        </a>
                        <ul className="contact-info mb-3 mt-3">
                          <li>
                            <span className="contact-info-label">
                              The nations’ leading provider in lease-to-own
                              furniture and electronics
                            </span>
                          </li>
                        </ul>
                        <div className="social-icons">
                          <ALink
                            href="https://x.com/BuyOnTrust"
                            className="social-icon social-x icon-x"
                            title="Twitter"
                            target="_blank">
                            <LazyLoadImage src="/images/logo-x.svg" />
                          </ALink>
                          <ALink
                            href="https://www.facebook.com/BuyonTrust/"
                            className="social-icon"
                            title="Facebook"
                            target="_blank">
                            <LazyLoadImage src="/images/logo-fb.svg" />
                          </ALink>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex row">
                      <div className="widget mr-5">
                        <h4 className="widget-title">About</h4>
                        <ul className="links">
                          <li>
                            <a
                              href="javascript:void(0)"
                              style={{ cursor: 'pointer' }}
                              onClick={openModal}>
                              How it works?
                            </a>
                          </li>
                          <li>
                            <ALink href="/contact-us">Contact us</ALink>
                          </li>
                        </ul>
                      </div>

                      <div className="widget ml-5 mr-5 pl-5">
                        <h4 className="widget-title">Accounts & Orders</h4>
                        <ul className="links">
                          <li>
                            {isUserLogged ? (
                              <a href="javascript:void(0)" onClick={logOut}>
                                Logout
                              </a>
                            ) : (
                              <ALink href="/login">Login or Sign Up</ALink>
                            )}
                          </li>
                          <li>
                            <ALink href="/returns-replacements">
                              Returns & Replacements
                            </ALink>
                          </li>
                          <li>
                            <ALink href="/cancellations">Cancellations</ALink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="footer-bottom">
              <div className="container d-sm-flex align-items-center">
                <div className="footer-left">
                  <span className="footer-copyright">
                    ©2024 BuyOnTrust. All rights reserved
                  </span>
                </div>

                <div className="footer-right ml-auto mt-1 mt-sm-0">
                  <div className="payment-icons">
                    <ALink href="/privacy-policy" className="mr-5">
                      Privacy & Policy
                    </ALink>
                    <ALink href="/terms-condition">Terms & Condition</ALink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </footer>
  );
}

export default React.memo(Footer);
