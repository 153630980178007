import { withRouter } from 'next/router';

// Import Custom Component
import ALink from '../ALink';

// Import Utils
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { clearCategory, setCategory } from '../../../store/categories';
import { sections } from '../../../utils/data/shop';
import CategoryContainer from './category-containers/category-container';
import Image from 'next/image';

const categoryToBlockMap = {
  0: { listType: 'top_rated', title: 'Top Rated Products', limit: 2 },
  1: { listType: 'best_selling', title: 'Best Selling Products', limit: 2 },
  2: { listType: 'featured', title: 'Featured Products', limit: 3 },
  3: { listType: 'latest', title: 'Latest Products', limit: 2 },
  4: { listType: 'best_selling', title: 'Best Selling Products', limit: 2 },
  5: { listType: 'top_rated', title: 'Top Rated Products', limit: 3 },
  6: { listType: 'featured', title: 'Featured Products', limit: 3 }
};

function MainMenu({ router }) {
  const pathname = router.pathname;
  const categories = useSelector(state => state.categories.categories);
  const dispatch = useDispatch();

  const [categoryIndex, setCategoryIndex] = useState(null);
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = useState(null);

  const categoryLinkRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const ignoreClasses = [
        'category-link',
        'product-grid-three',
        'product-grid-two',
        'selected-product-block',
        'submenu'
      ];
      const shouldIgnoreClick = ignoreClasses.some(className =>
        event.target.classList.contains(className)
      );
      if (!shouldIgnoreClick && !event.target.classList.contains('megamenu')) {
        setCategoryIndex(null);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // function isOtherPage() {
  //   return mainMenu.other.find(variation => variation.url === pathname);
  // }

  const handleCategory = cat => {
    dispatch(setCategory(cat));
    router.push({
      pathname: '/shop',
      query: {
        filters: JSON.stringify({
          [sections.categories]: [cat.name],
          [sections.price]: {
            min: 0,
            max: 0
          },
          [sections.materials]: [],
          [sections.colors]: []
        })
      }
    });
  };

  const handleCatIndex = (value = null) => {
    if (value === categoryIndex) {
      setCategoryIndex(null);
    } else {
      setCategoryIndex(value);
    }
  };

  function sortCategoriesById(categories) {
    const categoriesCopy = [...categories];
    const sortedCategories = categoriesCopy.sort(
      (a, b) => a.category_id - b.category_id
    );
    return sortedCategories;
  }

  // useEffect(() => {
  //   if (categoryIndex !== null) {
  //     document.body.classList.add('shaded');
  //   } else {
  //     document.body.classList.remove('shaded');
  //   }
  // }, [categoryIndex]);

  return (
    <>
      <nav className="main-nav w-100">
        <ul className="menu sf-js-enabled sf-arrows w-100 menu-ul">
          <li
            className={pathname === '/' ? 'active' : ''}
            style={{ width: '24px', height: '28px' }}
            onClick={() => {
              dispatch(clearCategory());
            }}>
            <ALink href="/home">
              <img src="/images/home/home-icon.svg" alt="Home button" />
            </ALink>
          </li>
          <li className="d-flex align-center">
            {!!categories?.length &&
              sortCategoriesById(categories).map((category, index) => {
                if (category.name !== 'Hot Sale') {
                  return (
                    <a
                      ref={categoryLinkRef}
                      key={category.name + index}
                      onClick={() => handleCatIndex(index)}
                      onMouseEnter={() => setHoveredCategoryIndex(index)}
                      onMouseLeave={() => setHoveredCategoryIndex(null)}
                      className={`category-link ${
                        categoryIndex === index ? 'category-link-active' : ''
                      } pointer`}>
                      {category.name}
                      <Image
                        src={
                          hoveredCategoryIndex === index ||
                          categoryIndex === index
                            ? '/images/menu-item-icon-active.svg'
                            : '/images/menu-item-icon.svg'
                        }
                        width={10}
                        height={14}
                        style={{
                          cursor: 'pointer',
                          marginLeft: '3px',
                          pointerEvents: 'none'
                        }}
                      />
                    </a>
                  );
                } else {
                  return (
                    <a
                      key={category.name + index}
                      onClick={() => {
                        handleCatIndex(null);
                        handleCategory(category);
                      }}
                      className="category-link hot-sale d-flex pointer">
                      <img alt="sale" src="/images/fire.svg" />
                      {category.name}
                    </a>
                  );
                }
              })}
            {categoryIndex !== null && (
              <div className="megamenu mega d-flex justify-between">
                <div className="row w-100">
                  <div className="col-lg-12">
                    <ul className="submenu">
                      <CategoryContainer
                        categoryIndex={categoryIndex}
                        products={categories[categoryIndex]}
                        handleCatIndex={handleCatIndex}
                        addInfo={categoryToBlockMap[categoryIndex]}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default withRouter(MainMenu);
