import { create } from 'zustand';
import Cookie from 'js-cookie';
import { logOut } from '../utils/globalHelpers/globalHelpers';

const init = {
  open: false,
  ddToken: '',
  jwtToken: '',
  verificationCode: '',
  message: '',
  isUserRegistered: true,
  verifyAuthEmail: false,
  status: 'error',
  isUserLogged: false,
  formData: {
    address1: '',
    address2: '',
    city: '',
    company: '',
    country: 'US',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    state: '',
    zipcode: ''
  },
  actions: {
    phoneMode: true,
    emailModal: false,
    showNewAccountPanel: false,
    showUpdateAccountPanel: false,
    showVerificationCodePanel: false,
    phoneModeSignUp: false,
    showSmsReceivingMethodPanel: false
  },
  currentCustomerData: null,
  currentMethod: 'phone',
};

export const useAuthModalStore = create(set => ({
  ...init,
  changeHandler: value =>
    set(() => {
      return { ...value };
    }),
  closeModal: () =>
    set(() => {
      return init;
    }),
  openModal: typeOpen =>
    set(state => {
      const isSingUp = typeOpen === 'singUp';
      isSingUp && state.setActions('phoneModeSignUp');
      return { open: true, isUserRegistered: !!isSingUp };
    }),
  clearAll: () =>
    set(state => {
      return { ...init };
    }),
  setActions: (changeAction, isBool = true) =>
    set(state => {
      const newActions = {};
      Object.keys(state?.actions)?.forEach(key => {
        if (changeAction === key) newActions[key] = isBool;
        else newActions[key] = false;
      });
      return { actions: newActions };
    }),
    setCurrentMethod: method =>
      set(() => {
        if (method === 'phone' || method === 'email') {
          return { currentMethod: method };
        }
        return {};
    }),
  setDdToken: token =>
    set(state => {
      Cookie.set('DD_TOKEN', token, { expires: 7 });
      return { ddToken: token };
    }),
  setJwtToken: token =>
    set(state => {
      Cookie.set('JWT_TOKEN', token, { expires: 7 });
      return { jwtToken: token };
    }),
  setUserRegistered: value =>
    set(() => {
      return { isUserRegistered: value };
    }),
  monitorUserSessionFromCookie: () =>
    set(state => {
      const jwtToken = Cookie?.get?.('JWT_TOKEN');
      const ddToken = Cookie?.get?.('DD_TOKEN');
      return {
        isUserLogged: !!(jwtToken && ddToken),
        ...(!state.jwtToken ? { jwtToken } : {}),
        ...(!state.ddToken ? { ddToken } : {})
      };
    }),
  logOut: () =>
    set(state => {
      logOut();
      return {
        isUserLogged: false,
        jwtToken: '',
        ddToken: ''
      };
    }),
  setErrorMessage: data =>
    set(() => {
      return {
        message: data?.message,
        status: !!data?.success ? 'success' : 'error'
      };
    }),
  setUserData: data =>
    set(() => {
      const { customer } = data;
      return {
        currentCustomerData: data,
        formData: transformCustomer(customer)
      };
    })
}));

const transformCustomer = data => {
  return {
    address1: data.street_1 || '',
    address2: data.street_2 || '',
    city: data.city || '',
    company: data.company || '',
    // country: data.country_iso2 || 'US',
    country: 'US',
    email: data.email || '',
    firstName: data.first_name || '',
    lastName: data.last_name || '',
    state: data.state || '',
    zipcode: data.zip || '',
    phone: data.phone || ''
  };
};
