import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Actions
import { actions as CartAction } from '../../../../store/cart/actions';

// Import Custom Component
import ProductNav from '../product-nav';
import Qty from '../qty';
import ALink from '../../../common/ALink';
import CountDown from '../../../features/countdown';
import { Collapse } from '@kunukn/react-collapse';

function ProductDetailOne(props) {
  const router = useRouter();
  const {
    product,
    adClass = 'col-lg-7 col-md-6',
    prev,
    next,
    isNav = true,
    parent = '.product-single-default',
    isSticky = false
  } = props;
  const [attrs, setAttrs] = useState({ sizes: [], colors: [] });
  const [variant, setVariant] = useState(null);
  const [size, setSize] = useState(null);
  const [color, setColor] = useState(null);
  const [qty, setQty] = useState(1);
  const [isCopied, setIsCopied] = useState(false);

  // console.log('props', props);

  useEffect(() => {
    if (product) {
      let attributes = product.variants.reduce(
        (acc, cur) => {
          cur.size &&
            !acc.sizes.find(size => size.size === cur.size.size) &&
            acc.sizes.push(cur.size);
          cur.color &&
            !acc.colors.find(color => color.name === cur.color.name) &&
            acc.colors.push(cur.color);
          return acc;
        },
        { sizes: [], colors: [] }
      );
      // setAttrs( attributes ); // TODO: Clarify product structure
      initState();
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      let priceToggle = document.querySelector(`${parent} .price-toggle`);
      let variationToggle = document.querySelector(
        `${parent} .variation-toggle`
      );

      if ((attrs.sizes.length && !size) || (attrs.colors.length && !color)) {
        document.querySelector(`${parent} .shopping-cart`) &&
          document
            .querySelector(`${parent} .shopping-cart`)
            .classList.add('disabled');
        document.querySelector(`${parent} .sticky-cart .add-cart`) &&
          document
            .querySelector(`${parent} .sticky-cart .add-cart`)
            .classList.add('disabled');
        priceToggle &&
          priceToggle.classList.contains('expanded') &&
          priceToggle.click();
      } else {
        document.querySelector(`${parent} .shopping-cart`) &&
          document
            .querySelector(`${parent} .shopping-cart`)
            .classList.remove('disabled');
        document.querySelector(`${parent} .sticky-cart .add-cart`) &&
          document
            .querySelector(`${parent} .sticky-cart .add-cart`)
            .classList.remove('disabled');
        let index = product.variants.findIndex(item => {
          return (
            !(item.size && item.size.size !== size) &&
            !(item.color && item.color.name !== color)
          );
        });
        setVariant({ ...product.variants[index], id: index });
      }

      if (size !== null || color !== null) {
        variationToggle &&
          variationToggle.classList.contains('collapsed') &&
          variationToggle.click();
      } else {
        variationToggle &&
          variationToggle.classList.contains('expanded') &&
          variationToggle.click();
      }
    }
  }, [size, color]);

  useEffect(() => {
    if (variant && variant.id >= 0) {
      let priceToggle = document.querySelector(`${parent} .price-toggle`);
      priceToggle &&
        priceToggle.classList.contains('collapsed') &&
        priceToggle.click();
    }
  }, [variant]);

  function onAddCartClick(e) {
    e?.preventDefault?.();
    props.addToCart(product, qty);
  }

  function changeQty(value) {
    setQty(value);
  }

  function selectColor(name, e) {
    e.preventDefault();
    setColor(color !== name ? name : null);
  }

  function selectSize(name, e) {
    e.preventDefault();
    setSize(size !== name ? name : null);
  }

  function initState() {
    setSize(null);
    setColor(null);
    setQty(1);
  }

  function isDisabled(type, name) {
    if (type === 'color' && size) {
      return !product.variants.find(
        variant => variant.size.size === size && variant.color.name === name
      );
    } else if (type === 'size' && color) {
      return !product.variants.find(
        variant => variant.color.name === color && variant.size.size === name
      );
    }
    return false;
  }

  const handleShare = app => {
    const currentUrl = `${window.location.origin}/product/default/${product.id}`;
    let shareUrl = '';

    switch (app) {
      case 'mail':
        shareUrl = `mailto:?subject=${'Check this out on BuyOnTrust!'}&body=${`I want to recommend this product at BuyOnTrust: ${currentUrl}`}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
        break;
      case 'x':
        shareUrl = `https://x.com/intent/tweet?url=${currentUrl}text=${`${product?.name ||
          'Product'} at BuyOnTrust: ${currentUrl}`}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  };

  const copyToClipboard = () => {
    const currentUrl = `${window.location.origin}/product/default/${product.id}`;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setIsCopied(true);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <>
      <div className={`skel-pro skel-detail ${adClass}`} />
      {product && (
        <div className={`product-single-details ${adClass}`}>
          <h1 className="product-title">{product.name}</h1>

          {isNav ? <ProductNav next={next} prev={prev} /> : ''}

          {/*<div className="ratings-container">*/}
          {/*    <div className="product-ratings">*/}
          {/*        <span*/}
          {/*            className="ratings"*/}
          {/*            style={{ width: `${20 * product.ratings}%` }}*/}
          {/*        />*/}
          {/*        <span className="tooltiptext tooltip-top">*/}
          {/*            {product.ratings.toFixed(2)}*/}
          {/*        </span>*/}
          {/*    </div>*/}

          {/*    <ALink className="rating-link" href="#">*/}
          {/*        ({' '}*/}
          {/*        {product.reviews > 0*/}
          {/*            ? `${product.reviews} Reviews`*/}
          {/*            : 'There are no reviews yet.'}{' '}*/}
          {/*        )*/}
          {/*    </ALink>*/}
          {/*</div>*/}

          <hr className="short-divider" />

          <div className="price-box">
            {product?.sale_end?.sale_in_seconds > 0 ? (
              <>
                <span className="product-price">
                  {'$' + product?.price[0]?.toFixed(2)}
                </span>
                <span className="old-price ml-2">
                  {'$' + product?.price[1]?.toFixed(2)}
                </span>
                <div className="product-home-sale">
                  <LazyLoadImage width={11} src="/images/fire.svg" />
                  <span className="product-home-sale-text">
                    Sale Ends in:
                    <span className="product-home-sale-text-lower">
                      <span className="product-home-sale-text-bold">
                        {product.sale_end.days}
                      </span>{' '}
                      d{' '}
                      <span className="product-home-sale-text-bold">
                        {product.sale_end.hours}
                      </span>{' '}
                      h{' '}
                      <span className="product-home-sale-text-bold">
                        {product.sale_end.minutes}
                      </span>{' '}
                      m
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <span className="product-price">
                {'$' + product?.price[0]?.toFixed(2)}
              </span>
            )}
          </div>

          {!product?.price?.length && (
            <div className="price-box">
              <span className="new-price">
                {'$' + product?.price?.toFixed?.(2)}
              </span>
            </div>
          )}

          {product.until && product.until !== null && <CountDown type="1" />}

          <div className="product-desc">
            {/*<p>{product.short_description}</p>*/}

            <div
              dangerouslySetInnerHTML={{
                __html: product.short_description
              }}
            />
          </div>

          <ul className="single-info-list">
            {product.sku ? (
              <li>
                SKU: <strong>{product.sku}</strong>
              </li>
            ) : (
              ''
            )}

            <li>
              CATEGORY:{' '}
              {product.categories.map((item, index) => (
                <React.Fragment key={`single-cat-${index}`}>
                  <strong>
                    <ALink
                      className="category"
                      href={{
                        pathname: '/shop',
                        query: { category: item.slug }
                      }}>
                      {item.name}
                    </ALink>
                  </strong>
                  {index < product.categories.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </li>

            {!product.tags == null && product.tags.length > 0 ? (
              <li>
                TAGs:{' '}
                {product.tags.map((item, index) => (
                  <React.Fragment key={`single-cat-${index}`}>
                    <strong>
                      <ALink
                        className="category"
                        href={{
                          pathname: '/shop',
                          query: { tag: item.slug }
                        }}>
                        {item.name}
                      </ALink>
                    </strong>
                    {index < product.tags.length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))}
              </li>
            ) : (
              ''
            )}
          </ul>

          {product.variants.length > 0 ? (
            <div className="product-filters-container">
              {attrs.colors.length > 0 ? (
                <div className="product-single-filter d-flex align-items-center">
                  <label>Color:</label>
                  <ul className="config-size-list config-color-list config-filter-list">
                    {attrs.colors.map((item, index) => (
                      <li
                        className={`${item.name === color ? 'active' : ''} ${
                          isDisabled('color', item.name) ? 'disabled' : ''
                        }`}
                        key={`filter-color-${index}`}>
                        {item.thumb ? (
                          <a
                            className="filter-thumb p-0"
                            href="#"
                            onClick={e => selectColor(item.name, e)}>
                            <LazyLoadImage
                              alt="product thumb"
                              height={item.thumb.height}
                              src={
                                process.env.NEXT_PUBLIC_ASSET_URI +
                                item.thumb.url
                              }
                              width={item.thumb.width}
                            />
                          </a>
                        ) : (
                          <a
                            className="filter-color border-0"
                            href="#"
                            onClick={e => selectColor(item.name, e)}
                            style={{
                              backgroundColor: item.color
                            }}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ''
              )}

              {attrs.sizes.length > 0 ? (
                <div className="product-single-filter d-flex align-items-center">
                  <label>Size:</label>
                  <ul className="config-size-list d-inline-block">
                    {attrs.sizes.map((item, index) => (
                      <li
                        className={`${item.size === size ? 'active' : ''} ${
                          isDisabled('size', item.size) ? 'disabled' : ''
                        }`}
                        key={`filter-size-${index}`}>
                        {item.thumb ? (
                          <a
                            className="filter-thumb p-0"
                            href="#"
                            onClick={e => selectSize(item.size, e)}>
                            <LazyLoadImage
                              alt="product thumb"
                              height={item.thumb.height}
                              src={
                                process.env.NEXT_PUBLIC_ASSET_URI +
                                item.thumb.url
                              }
                              width={item.thumb.width}
                            />
                          </a>
                        ) : (
                          <a
                            className="d-flex align-items-center justify-content-center"
                            href="#"
                            onClick={e => selectSize(item.size, e)}>
                            {item.name}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {isSticky && (
            <div className="sticky-wrapper">
              <div className="sticky-header desktop-sticky sticky-cart d-none d-lg-block">
                <div className="container">
                  <div className="sticky-img mr-4 media-with-lazy">
                    <figure className="mb-0">
                      <LazyLoadImage
                        alt="Thumbnail"
                        height="auto"
                        src={
                          process.env.NEXT_PUBLIC_ASSET_URI +
                          product.small_pictures[0].url
                        }
                        width="100%"
                      />
                    </figure>
                  </div>
                  <div className="sticky-detail">
                    <div className="sticky-product-name">
                      <h2 className="product-title w-100 ls-0">
                        {product.name}
                      </h2>
                      <div className="price-box">
                        {variant && variant.id >= 0 ? (
                          variant.price ? (
                            <span className="product-price">
                              ${variant && variant.price.toFixed(2)}
                            </span>
                          ) : (
                            <span className="product-stock pt-3 d-block">
                              {product.is_out_of_stock
                                ? 'Out of Stock'
                                : `${product.stock} in stock`}
                            </span>
                          )
                        ) : product.price[0] == product.price[1] ? (
                          <span className="product-price">
                            {'$' + product.price[0].toFixed(2)}
                          </span>
                        ) : product.variants.length > 0 ? (
                          <span className="product-price">
                            {'$' + product.price[0].toFixed(2)} &ndash;{' '}
                            {'$' + product.price[1].toFixed(2)}
                          </span>
                        ) : (
                          <>
                            <span className="old-price">
                              {'$' + product.price[1].toFixed(2)}
                            </span>
                            <span className="new-price">
                              {'$' + product.price[0].toFixed(2)}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span
                          className="ratings"
                          style={{
                            width: `${20 * product.ratings}%`
                          }}
                        />
                        <span className="tooltiptext tooltip-top">
                          {product.ratings.toFixed(2)}
                        </span>
                      </div>

                      <ALink className="rating-link" href="#">
                        ({' '}
                        {product.reviews > 0
                          ? `${product.reviews} Reviews`
                          : 'There are no reviews yet.'}{' '}
                        )
                      </ALink>
                    </div>
                  </div>

                  <div className="product-action">
                    <Qty
                      max={product.stock}
                      onChangeQty={changeQty}
                      value={qty}
                    />

                    <a
                      className={`btn btn-dark add-cart mr-2 ${
                        attrs.sizes.length > 0 || attrs.colors.length > 0
                          ? 'disabled'
                          : ''
                      }`}
                      href="#"
                      onClick={onAddCartClick}
                      title="Add To Cart">
                      Add to Cart
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="product-action">
            {product.variants.length ? (
              <Collapse
                isOpen
                transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                <>
                  <button className={'d-none price-toggle'} />
                  <div className="price-box product-filtered-price m-0">
                    {variant &&
                      variant.id >= 0 &&
                      (variant.price ? (
                        variant.sale_price ? (
                          <>
                            <del className="old-price">
                              <span>${variant.price.toFixed(2)}</span>
                            </del>
                            <span className="product-price">
                              ${variant && variant.sale_price.toFixed(2)}
                            </span>
                          </>
                        ) : (
                          <span className="product-price">
                            ${variant && variant.price.toFixed(2)}
                          </span>
                        )
                      ) : (
                        <span className="product-stock pb-3 d-block">
                          {product.is_out_of_stock
                            ? 'Out of Stock'
                            : `${product.stock} in stock`}
                        </span>
                      ))}
                  </div>
                </>
              </Collapse>
            ) : (
              ''
            )}

            <Qty max={product.stock} onChangeQty={changeQty} value={qty} />

            <a
              className={`btn btn-dark add-cart shopping-cart mr-2 ${
                attrs.sizes.length > 0 || attrs.colors.length > 0
                  ? 'disabled'
                  : ''
              }`}
              href="#"
              onClick={onAddCartClick}
              title="Add To Cart">
              Add to Cart
            </a>
          </div>

          <hr className="divider mb-0 mt-0" />

          <div className="product-single-share">
            <div className="mt-1">
              <div
                className="share-menu__element ml-3"
                onClick={() => handleShare('mail')}>
                <LazyLoadImage src="/images/mail-icon-black.svg" />
              </div>
              <div
                className="share-menu__element ml-3"
                onClick={() => handleShare('facebook')}>
                <LazyLoadImage src="/images/fb-icon-black.svg" />
              </div>
              <div
                className="share-menu__element ml-3"
                onClick={() => handleShare('x')}>
                <LazyLoadImage src="/images/x-icon-black.svg" />
              </div>
              <div
                className="share-menu__element ml-3"
                onClick={copyToClipboard}>
                <LazyLoadImage src="/images/link-icon-black.svg" />
                <span>{isCopied ? 'Link copied!' : 'Copy Link'}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : []
  };
};

export default connect(mapStateToProps, { ...CartAction })(ProductDetailOne);
