import { call, select, takeEvery, put } from 'redux-saga/effects';
import { actionTypes } from './constants';
import Cookies from 'js-cookie';
import { actions } from './actions';
import { fetchAxios } from '../../utils/fetchAxios/fetchAxios';
import { toast } from 'react-toastify';
import CartPopup from '../../components/features/modals/add-to-cart-popup';

function* fetchCart({ paramsUrl, method, inPutData }) {
  try {
    const { data } = yield call(
      fetchAxios,
      `/buyontrust/storefront/furniture/cart${
        paramsUrl ? '/' + paramsUrl : ''
      }`,
      {
        method,
        data: inPutData
      }
    );

    yield put(actions.refreshCartStore(data || {}));
    yield put(actions.setAvailableCartItems(data.cartItems || []));
  } catch (e) {}
}

function* fetchProductListBySku({ method, inPutData }) {

  // console.log('fetchProductListBySku', method, inPutData);

  try {
    const { data } = yield call(
      fetchAxios,
      '/public/buyontrust/products_by_sku',
      {
        method,
        data: inPutData
      }
    );
    // console.log('fetchProductListBySku data', data);

    // yield put(actions.refreshCartStore(data?.products || []));
    yield put(actions.setAvailableCartItems(data.products || {}));

  } catch (e) {}
}

export function* cartSaga() {

  yield takeEvery(actionTypes.GetActualCart, function* saga(e) {
    // console.log('GetActualCart');

    try {

      const { cartItems } = yield select(state => state.cart);
      // console.log(cartItems);

      const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

      if (!getDD_TOKEN()) {
        // yield put(actions.refreshCartStore()); // <----????
        return;
      }

      yield call(fetchProductListBySku, {
        method: 'post',
        inPutData: { sku_list: cartItems.map(item => item.sku) }
      });

      toast(<CartPopup product={{ ...e.payload.product, index: e.index }} />);

    } catch (error) {}
  });

  yield takeEvery(actionTypes.AddToCart, function* saga(e) {
    try {
      const { timesCartItems } = yield select(state => state.cart);
      const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

      if (!getDD_TOKEN()) {
        yield put(actions.refreshCartStore({ cartItems: timesCartItems } || []));
      } else {
        yield call(fetchCart, {
          method: 'post',
          inPutData: { cartItems: timesCartItems }
        });
      }

      // Always show toast
      toast(<CartPopup product={{ ...e.payload.product, index: e.index }} />);
    } catch (error) {}
  });
  yield takeEvery(actionTypes.UpdateCart, function* saga() {
    try {
      const { timesCartItems } = yield select(
        state => state.cart
      );
      const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

      if (!getDD_TOKEN()) {
        yield call(updateCartLocal, {
          product: timesCartItems?.product,
          newQty: timesCartItems?.newQty
        });
        return;
      }

      yield call(fetchCart, {
        method: 'put',
        inPutData: { qty: timesCartItems?.newQty },
        paramsUrl: timesCartItems?.product?.sku
      });
    } catch (error) {}
  });

  yield takeEvery(actionTypes.RemoveFromCart, function* saga() {
    try {
      const { timesCartItems } = yield select(state => state.cart);
      const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

      if (!getDD_TOKEN()) {
        yield call(removeFromCartLocal, {
          product: timesCartItems
        });
        return;
      }

      yield call(fetchCart, {
        method: 'delete',
        paramsUrl: timesCartItems?.sku
      });
    } catch (error) {}
  });
}

function* updateCartLocal({ product, newQty }) {
  const { cartItems } = yield select(state => state.cart);
  const response =
        newQty === 0
          ? []
          : cartItems?.map(item => {
            if (product?.sku === item?.sku) {
              item.qty = newQty;
            }
            return item;
          });
  yield put(actions.refreshCartStore({cartItems: response} || []));
}

function* removeFromCartLocal({ product }) {
  const { cartItems } = yield select(state => state.cart);
  const response = cartItems?.filter(item => item?.sku !== product?.sku);
  yield put(actions.refreshCartStore({cartItems: response} || []));
}
