import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { clearAllCookies } from '../сookies/cookies';
import { clearLocalStorage } from '../storage/storage';
import { logOut } from '../globalHelpers/globalHelpers';

const clearStorage = () => {
  clearAllCookies()
  clearLocalStorage()
}

export const fetchAxios = async (url, requestConfig, apiVersion=2) => {

  let baseUrl = process.env.NEXT_PUBLIC_SERVER_URL;
  if (apiVersion === 1) baseUrl = baseUrl.replace('v2', 'v1');

  // console.log('url', baseUrl + url);

  // if (apiVersion === 1) return {return: 'return'};

  try {
    const getDD_TOKEN = () => Cookies.get('DD_TOKEN');
    const updatedRequestConfig = {
      url: baseUrl + url,
      ...requestConfig,
      headers: {
        'Content-Type': 'application/json',
        ...(getDD_TOKEN() ? { 'X-Auth-Token': getDD_TOKEN() } : {})
      }
    };

    const response = await axios(updatedRequestConfig);

    return response;
  } catch (err) {

    if (err?.response?.data?.isLoggedIn === false) {
      clearStorage()

      if (window.location.pathname.includes('/home') || window.location.pathname.includes('/shop')) {
        window.location.reload()
        return
      }

      if (window.location.pathname.includes('/cart') || window.location.pathname.includes('/checkout') || window.location.pathname.includes('/account')) {
        window.location.href = `/login?from=${window.location.pathname}`
        return
      }

      logOut()
    } else {

      toast(err?.response?.data?.message || err.message, {
        style: { background: 'red', color: 'white' }
      });
  
      if (err?.response?.status === 401) {
        clearStorage()
        window.location.href = `/login?from=${window.location.pathname}`
      }
  
      console.error(err);
      throw err;
    }
  }
};
