import { all } from 'redux-saga/effects';
import { cartSaga } from './cart/sagas';
import { wishlistSaga } from './wishlist';
import { categoriesSaga } from './categories';
import {  watchCheckoutSaga } from './checkout/sagas';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    cartSaga(),
    wishlistSaga(),
    categoriesSaga(),
    watchCheckoutSaga(),
  ]);
}
