// Import Custom Component
import ALink from './ALink';
import CartMenu from './partials/cart-menu';
import MainMenu from './partials/main-menu';
import SearchForm from './partials/search-form';
import { useAuthModalStore } from '../../store/auth-modal';
import versionConfig from '../../environments/versionConfig';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CategoryMobile from './partials/category-containers/category-mobile';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import useApproveModalStore from '../../store/approve-modal';
import Image from 'next/image';

export default function Header({ adClass = '' }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    setActions,
    logOut,
    setUserRegistered,
    currentCustomerData,
    isUserLogged
  } = useAuthModalStore();
  const { openModal } = useApproveModalStore();
  //
  // function openMobileMenu(e) {
  //   e.preventDefault();
  //   document.querySelector('body').classList.toggle('mmenu-active');
  //   e.currentTarget.classList.toggle('active');
  // }
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (currentCustomerData) {
      setIsUserDataLoaded(true);
    }
  }, [currentCustomerData]);

  useEffect(() => {
    const checkAndApplyPadding = () => {
      const mainTag = document.querySelector('main');
      const headerDiv = document.querySelector('.header-approval-outter');
      if (
        mainTag &&
        window.innerWidth < 721 &&
        headerDiv &&
        headerDiv.classList.contains('header-mobile-show')
      ) {
        mainTag.style.paddingTop = '134px';
      } else if (mainTag) {
        mainTag.style.paddingTop = '';
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          checkAndApplyPadding();
        }
      }
    });

    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    window.addEventListener('resize', checkAndApplyPadding);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', checkAndApplyPadding);
    };
  }, [isUserLogged, currentCustomerData]);

  let leaseProviderName = '';
  if (currentCustomerData?.lease_data[0]?.name === 'uown') {
    leaseProviderName = 'UOwn';
  } else if (currentCustomerData?.lease_data[0]?.name === 'uown_furniture') {
    leaseProviderName = 'UOwn (for furniture)';
  } else {
    leaseProviderName = currentCustomerData?.lease_data[0]?.name.toUpperCase();
  }

  const nodeEnv = process.env.NODE_ENV;
  console.log('Env =', nodeEnv);

  return (
    <header className={`header ${adClass}`}>
      <div
        className={`header-approval-outter header-approval-outter-mobile ${
          isUserLogged &&
          currentCustomerData &&
          currentCustomerData?.lease_data[0]?.approval_amount !== 0
            ? 'header-mobile-show'
            : 'header-mobile-hidden'
        }`}>
        <div className={'provider-element'}>
          <span className="header-approval-button-approval-balance">
            {currentCustomerData?.lease_data[0]?.name === 'bot'
              ? 'Pre Qualified'
              : 'Available'}
            :
          </span>
          <span className="style-approve-amount-dollar">
            $
            {currentCustomerData?.lease_data[0]?.approval_amount?.toLocaleString?.(
              'en-US'
            ) + '.'}
          </span>
          <span className="style-approve-amount-cent">00</span>

          {currentCustomerData?.lease_data[0]?.name !== 'bot' && (
            <>
              <span className="header-approval-button-approval-balance">
                {' by'}
              </span>

              <span className="style-approve-provider">
                {currentCustomerData?.lease_data[0]?.name === 'uown'
                  ? 'UOwn'
                  : currentCustomerData?.lease_data[0]?.name.toUpperCase()}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="header-top">
        <div className="header-row container">
          <div className="burger-menu">
            <button
              className="burger-button"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <LazyLoadImage src="/images/burger-menu.svg" />
            </button>
          </div>
          <div className="wel-msg text-uppercase d-lg-block">
            <ALink href="/">
              <img
                alt="BuyOnTrust Logo"
                src="/images/home/logo_red.png"
                style={{ width: '85%' }}
              />
              <div className={'version'}>
                {nodeEnv !== 'production' ? `v ${versionConfig.version}` : ''}
              </div>
            </ALink>
          </div>

          {!isUserDataLoaded ? (
            <span className="menu-item">
              <ALink
                className="login-link"
                href={'/login'}
                onClick={() => {
                  setActions('phoneMode');
                  setUserRegistered(true);
                }}>
                Log In
              </ALink>
              {'/'}
              <ALink
                className="login-link"
                href={'/login'}
                onClick={() => {
                  setActions('phoneModeSignUp');
                  setUserRegistered(false);
                }}>
                Sign Up
              </ALink>
            </span>
          ) : (
            <>
              <ALink className="customer-data" href={'/account'}>
                <LazyLoadImage src="/images/header/account.svg" />
                <span className="customer-name">
                  {`${currentCustomerData?.customer?.first_name} ${currentCustomerData?.customer?.last_name}`}
                </span>
              </ALink>
              <span className="menu-item">
                <a className="login-link" onClick={logOut}>
                  Logout
                </a>
              </span>
            </>
          )}

          {/* </ul> */}
          {/* </div> */}

          {/* <span className="separator d-none d-xl-block"></span> */}

          {/* <div className="share-links d-none d-xl-block">
                        <ALink
                            href="#"
                            className="share-facebook icon-facebook"></ALink>
                        <ALink
                            href="#"
                            className="share-twitter icon-twitter"></ALink>
                        <ALink
                            href="#"
                            className="share-instagram icon-instagram"></ALink>
                    </div> */}

          <div className="header-approval-outter">
            <div
              className={`provider-element 
                ${
                  isUserLogged &&
                  currentCustomerData &&
                  currentCustomerData?.lease_data[0]?.approval_amount !== 0
                    ? 'header-approval-outter-content-show'
                    : 'header-approval-outter-content-hidden'
                }`}>
              <span className="header-approval-button-approval-balance">
                {currentCustomerData?.lease_data[0]?.name === 'bot'
                  ? 'Pre Qualified'
                  : 'Available'}
                :
              </span>
              <span className="style-approve-amount-dollar">
                $
                {currentCustomerData?.lease_data[0]?.approval_amount?.toLocaleString?.(
                  'en-US'
                ) + '.'}
              </span>
              <span className="style-approve-amount-cent">00</span>

              {currentCustomerData?.lease_data[0]?.name !== 'bot' && (
                <>
                  <span className="header-approval-button-approval-balance">
                    {' by'}
                  </span>

                  <span className="style-approve-provider">
                    {leaseProviderName}
                  </span>
                </>
              )}
            </div>

            <div
              className={`header-approval-button ${
                !currentCustomerData ||
                currentCustomerData?.lease_data[0]?.approval_amount === 0
                  ? 'header-approval-outter-content-show'
                  : 'header-approval-outter-content-hidden'
              }`}>
              <a className="pointer" onClick={openModal} target="_blank">
                Get&nbsp;Approved!
              </a>
            </div>
          </div>
          <div className="header-right-mobile">
            <ALink
              className="header-icon header-icon-user"
              href={isUserLogged ? '/account' : '/login'}
              title="login">
              <Image src={'/images/user-icon.svg'} width={32} height={32} />
            </ALink>

            <SearchForm />

            <CartMenu />
          </div>
        </div>
      </div>

      <div className="header-middle  desktop-sticky main-menu-desktop">
        <div className="container">
          <div className="header-left d-lg-block pl-0 is-call-visible mr-lg-5">
            <div className="header-contact d-none d-lg-flex align-items-center pl-1 mr-lg-5 pr-xl-2">
              <Image
                src={'/images/header-phone-icon.svg'}
                width={24}
                height={24}
              />
              <h6>
                Call us now
                <a className="text-dark font1" href="tel:+18882747732">
                  888-274-7732
                </a>
              </h6>
            </div>
          </div>
          <MainMenu />

          {/* <div className="header-center">
            <button
              className="mobile-menu-toggler"
              type="button"
              onClick={openMobileMenu}>
              <i className="fas fa-bars"></i>
            </button>
            <ALink href="/" className="logo">
              <img src="/images/home/logo_red.png" alt="BuyOnTrust Logo" />
              <div className={'version'}>v {versionConfig.version}</div>
            </ALink>
          </div> */}

          <div className="d-flex ">
            <ALink
              className="header-icon header-icon-user"
              href={isUserLogged ? '/account' : '/login'}
              title="login">
              <Image
                src={'/images/user-icon.svg'}
                width={32}
                height={32}
                style={{ maxWidth: 'none' }}
              />
            </ALink>

            <SearchForm />

            <CartMenu />
          </div>
        </div>
      </div>
      <div className="burger-menu">
        {isMobileMenuOpen && (
          <CategoryMobile setIsMobileMenuOpen={setIsMobileMenuOpen} />
        )}
      </div>
    </header>
  );
}
