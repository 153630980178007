import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

// Import Custom Component
function SearchForm() {
  const router = useRouter();
  const query = router.query;
  // const [cat, setCat] = useState('');
  const [search, setSearch] = useState('');
  // const [timer, setTimer] = useState(null);
  //
  // const categories = useSelector(state => state.categories.categories);
  // const dispatch = useDispatch();

  const inputRef = useRef(null);

  useEffect(() => {
    document.querySelector('body').addEventListener('click', onBodyClick);

    return () => {
      document.querySelector('body').removeEventListener('click', onBodyClick);
    };
  }, []);

  useEffect(() => {
    setSearch('');
    // setCat('');
  }, [router.query.slug]);

  useEffect(() => {
    document.querySelector('.header-search.show-results') &&
      document
        .querySelector('.header-search.show-results')
        .classList.remove('show-results');
  }, [router.pathname]);

  // function removeXSSAttacks(html) {
  //   const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  //
  //   // Removing the <script> tags
  //   while (SCRIPT_REGEX.test(html)) {
  //     html = html.replace(SCRIPT_REGEX, '');
  //   }
  //
  //   // Removing all events from tags...
  //   html = html.replace(/ on\w+="[^"]*"/g, '');
  //
  //   return {
  //     __html: html
  //   };
  // }

  // function matchEmphasize(name) {
  //   let regExp = new RegExp(search, 'i');
  //   return name.replace(regExp, match => '<strong>' + match + '</strong>');
  // }

  function onSearchClick(e) {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.parentNode.classList.toggle('show');
    inputRef.current.focus();
  }

  function onBodyClick(e) {
    if (e.target.closest('.header-search'))
      return (
        e.target.closest('.header-search').classList.contains('show-results') ||
        e.target.closest('.header-search').classList.add('show-results')
      );

    document.querySelector('.header-search.show') &&
      document.querySelector('.header-search.show').classList.remove('show');
    document.querySelector('.header-search.show-results') &&
      document
        .querySelector('.header-search.show-results')
        .classList.remove('show-results');
  }

  // function onCatSelect(e) {
  //   console.log(e);
  //   setCat(e.target.value);
  // }

  function onSearchChange(e) {
    setSearch(e.target.value);
  }

  function onSubmitSearchForm(e) {
    e.preventDefault();
    // const selectedCategoty = findCategory(cat, categories)
    // dispatch(setCategory(selectedCategoty))
    router.push({
      pathname: '/shop',
      query: {
        ...query,
        page: 1,
        search: search
        // category: cat
      }
    });
    setSearch('');
  }

  return (
    <div className="header-search header-search-popup header-search-category">
      <a
        href="#"
        className="search-toggle"
        role="button"
        onClick={onSearchClick}>
        <Image
          src={'/images/search-icon.svg'}
          width={32}
          height={32}
          style={{ maxWidth: 'none' }}
        />
      </a>
      <form action="#" method="get" onSubmit={e => onSubmitSearchForm(e)}>
        <div className="header-search-wrapper">
          <input
            ref={inputRef}
            type="search"
            className="form-control"
            name="q"
            id="q"
            placeholder="Search..."
            value={search}
            required
            onChange={e => onSearchChange(e)}
          />
          {/*<div className="select-custom">*/}
          {/*    <select*/}
          {/*        id="cat"*/}
          {/*        name="cat"*/}
          {/*        value={cat}*/}
          {/*        onChange={e => onCatSelect(e)}>*/}
          {/*        <option value="">All Categories</option>*/}
          {/*        {categories?.map((cat, index) => {*/}
          {/*            const nodeArr = [*/}
          {/*                <option key={cat.name+index} value={cat.slug}>*/}
          {/*                    {cat.name}*/}
          {/*                </option>*/}
          {/*            ];*/}
          {/*            if (cat.children.length > 0) {*/}
          {/*                cat.children.forEach((childCat, i) => {*/}
          {/*                    nodeArr.push(*/}
          {/*                        <option*/}
          {/*                            key={childCat + i}*/}
          {/*                            value={childCat.slug}>*/}
          {/*                            {`- ${childCat.name}`}*/}
          {/*                        </option>*/}
          {/*                    );*/}
          {/*                });*/}
          {/*            }*/}
          {/*            return nodeArr;*/}
          {/*        })}*/}
          {/*    </select>*/}
          {/*</div>*/}
          <button
            className="btn-clr"
            title="search"
            onClick={() => {
              setSearch('');
            }}
            type="button">
            ×
          </button>
          <button
            className="btn d-flex align-items-center justify-content-center"
            title="search"
            type="submit">
            <Image
              src={'/images/search-icon-white.svg'}
              width={25}
              height={25}
            />
          </button>

          {/*<div className="live-search-list bg-white">*/}
          {/*    {search.length > 2 &&*/}
          {/*        categories &&*/}
          {/*        categories.map((product, index) => (*/}
          {/*            <ALink*/}
          {/*                href={`/product/default/${product.slug}`}*/}
          {/*                className="autocomplete-suggestion"*/}
          {/*                key={`search-result-${index}`}>*/}
          {/*                <LazyLoadImage*/}
          {/*                    src={*/}
          {/*                        product?.small_pictures?.length*/}
          {/*                            ? process.env*/}
          {/*                                  .NEXT_PUBLIC_ASSET_URI +*/}
          {/*                              product?.small_pictures[0]*/}
          {/*                                  ?.url*/}
          {/*                            : ''*/}
          {/*                    }*/}
          {/*                    width={40}*/}
          {/*                    height={40}*/}
          {/*                    alt="product"*/}
          {/*                />*/}
          {/*                <div*/}
          {/*                    className="search-name"*/}
          {/*                    dangerouslySetInnerHTML={removeXSSAttacks(*/}
          {/*                        matchEmphasize(product.name)*/}
          {/*                    )}></div>*/}
          {/*                <span className="search-price">*/}
          {/*                    {product?.price?.[0] ==*/}
          {/*                    product?.price?.[1] ? (*/}
          {/*                        <span className="product-price">*/}
          {/*                            {'$' +*/}
          {/*                                product?.price?.[0].toFixed(2)}*/}
          {/*                        </span>*/}
          {/*                    ) : product?.variants?.length > 0 ? (*/}
          {/*                        <span className="product-price">*/}
          {/*                            {'$' +*/}
          {/*                                product?.price?.[0].toFixed(*/}
          {/*                                    2*/}
          {/*                                )}{' '}*/}
          {/*                            &ndash;{' '}*/}
          {/*                            {'$' +*/}
          {/*                                product?.price?.[1].toFixed(2)}*/}
          {/*                        </span>*/}
          {/*                    ) : (*/}
          {/*                        <>*/}
          {/*                            <span className="old-price">*/}
          {/*                                {'$' +*/}
          {/*                                    product?.price?.[1].toFixed(*/}
          {/*                                        2*/}
          {/*                                    )}*/}
          {/*                            </span>*/}
          {/*                            <span className="product-price">*/}
          {/*                                {'$' +*/}
          {/*                                    product?.price?.[0].toFixed(*/}
          {/*                                        2*/}
          {/*                                    )}*/}
          {/*                            </span>*/}
          {/*                        </>*/}
          {/*                    )}*/}
          {/*                </span>*/}
          {/*            </ALink>*/}
          {/*        ))}*/}
          {/*</div>*/}
        </div>
      </form>
    </div>
  );
}

export default SearchForm;
