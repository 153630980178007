import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../../common/ALink';
import { setCategory } from '../../../store/categories';
import { useRouter } from 'next/router';
import { sections } from '../../../utils/data/shop';
import {useDispatch } from 'react-redux';
import PlaceholderImage from '../../common/PlaceholderImage';

function SelectedProductsItem({ product }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const query = router.query;
  return (
    <ALink
      href={`/product/default${product.slug}`}
      className="selected-products-item">
      <PlaceholderImage
        alt="product"
        src={
          (!product?.pictures[0]?.url?.includes?.('https:')
            ? process.env.NEXT_PUBLIC_ASSET_URI
            : '') + product.pictures[0]?.url
        }
        threshold={500}
        effect="black and white"
        width="140px"
        height="140px"
        position={'relative'}
        className="selected-products-item-img"
      />
      <div className="selected-products-item-wrapper">
        <span className="selected-products-item-category">
            {product.categories
              ? product.categories.map((item, index) => (
                  <React.Fragment key={item.slug + '-' + index}>
                    <ALink 
                      className='p-0 selected-products-item-category-link'
                      onClick={() => {
                        dispatch(setCategory(item));
                      }}
                      href={{
                        pathname: '/shop',
                        // query: { category: item.slug }
                        query: {
                          ...query,
                          filters: JSON.stringify({
                            [sections.categories]: [item.name],
                            [sections.price]: { min: 0, max: 0 },
                            [sections.materials]: [],
                            [sections.colors]: []
                          })
                        }
                      }}>
                      {item.name}
                    </ALink>
                    {index < product.categories.length - 1 ? ' ,' : ''}
                  </React.Fragment>
                ))
              : ''}
        </span>
        <h5 className={`selected-products-item-title ${product?.sale_end?.sale_in_seconds > 0 ? 'selected-products-item-title-updated' : ''}`}>{product?.name}</h5>
        <div className="price-box price-box-home">
          {product?.sale_end?.sale_in_seconds > 0 ? (
            <>
              <span className="product-price product-home-price">
                {'$' + product?.price[0]?.toFixed(2)}
              </span>
              <span className="old-price product-home-old-price">
                {'$' + product?.price[1]?.toFixed(2)}
              </span>
              <div className="product-home-sale">
                <LazyLoadImage
                  className="product-home-sale-icon"
                  width={'11px'}
                  src="/images/fire.svg"
                />
                <span className="product-home-sale-text">
                  Sale Ends in:
                  <span className="product-home-sale-text-lower">
                    {/* need field 'promotionTo' & 'promotionFrom' from BE */}
                    <span className="product-home-sale-text-bold">
                      {product.sale_end.days}
                    </span>{' '}
                    d
                    <span className="product-home-sale-text-bold">
                      {product.sale_end.hours}
                    </span>{' '}
                    h
                    <span className="product-home-sale-text-bold">
                      {product.sale_end.minutes}
                    </span>{' '}
                    m
                  </span>
                </span>
              </div>
            </>
          ) : (
            <span className="product-price product-home-price">
              {'$' + product?.price[0]?.toFixed(2)}
            </span>
          )}
        </div>
      </div>
    </ALink>
  );
}

export default React.memo(SelectedProductsItem);
